import React, { PureComponent, useContext } from 'react'
import axios from 'axios'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

import CourseCard from './CourseCard'
import { CenteredSection, Divider, DynamicPageSection, LazyLoad } from '../layout/PageComponents'
import Paginator from '../responsive/Paginator'
import Placeholder from './Placeholder'
import FilterContext from '../../../contexts/filter/FilterContext'
import { theme } from '../../../utils/theme'
import i18n from 'i18next'
import HostCard from './HostCard'
import { useTranslation } from 'react-i18next-new'
import Button from '../buttons/Button'
import ScreenSizeContext from '../../../contexts/screen_size/ScreenSizeContext'
import CourseDateCard from './CourseDateCard'
import CourseDatePlaceholder from './CourseDatePlaceholder'
import useLocation from '../../../hooks/useLocation'
import { isServerRendering } from '../../../utils/applicationHelper'
import ContentSideBarContext from '../../../contexts/content_side_bar/ContentSideBarContext'
import pageScroller from '../../../utils/pageScroller'

const Items = styled.div`
	max-width: 100%;
	margin: 0 auto;
	vertical-align: top;
	margin-bottom: ${theme.margins.large};
	${({ listType }) =>
		listType !== 'COURSE_DATE' &&
		css`
			display: flex;
			flex-wrap: wrap;
		`}
	& > * {
		${({ rowLength, optimizeRows, rows, cardsPerRow, cardsPerRowMarginString, listType }) =>
			listType !== 'COURSE_DATE' &&
			css`
				position: relative;
				display: inline-block;
				vertical-align: top;
				margin-top: ${theme.margins.large};
				${!cardsPerRow &&
				rowLength === 'three-thirds' &&
				css`
					${theme.breakpoints.tablet.upToAndIncluding} {
						width: calc(50% - ${theme.margins.XSmallInt / 2}px);
						&:nth-child(2n + 1) {
							margin-right: ${theme.margins.XSmall};
						}
						&:first-child,
						&:nth-child(2) {
							margin-top: 0;
						}
						&:nth-child(1n + ${optimizeRows ? '5' : rows * 2 + 1}) {
							display: none !important;
						}
					}
					${theme.breakpoints.tablet.overAndExcluding} {
						width: calc(25% - ${(theme.margins.smallInt * 3) / 4}px);
						&:not(:nth-child(4n + 4)) {
							margin-right: ${theme.margins.small};
						}
						&:first-child,
						&:nth-child(2),
						&:nth-child(3),
						&:nth-child(4) {
							margin-top: 0;
						}
						&:nth-child(1n + ${optimizeRows ? '5' : rows * 4 + 1}) {
							display: none !important;
						}
					}
				`};
				${cardsPerRow &&
				css`
					width: calc(100% / ${cardsPerRow} - ${theme.margins.XSmallInt * (cardsPerRow - 1)}px / ${cardsPerRow});
					&:not(:nth-child(${cardsPerRow}n + ${cardsPerRow})) {
						margin-right: ${theme.margins.XSmall};
					}
					${cardsPerRowMarginString}
					&:nth-child(1n + ${rows * cardsPerRow + 1}) {
						display: none !important;
					}
				`}
			`}
	}
`
const Title = styled.h2`
	&:only-child {
		margin-bottom: ${theme.margins.small};
	}
`
const Subtitle = styled.p`
	margin-bottom: ${theme.margins.small};
`
class CourseCardsWrapper extends PureComponent {
	constructor(props) {
		super(props)
		const { ssrCourseCardData, signedIn, title, subtitle, url, infiltrators, filterIdentifier } = props
		let initialCourseCards
		if ((isServerRendering() || !window.ssrFilterStateHasBeenUsed) && ssrCourseCardData) {
			initialCourseCards = ssrCourseCardData.courseCards // Load course cards from SSR
			if (!isServerRendering()) window.ssrFilterStateHasBeenUsed = true // Prevent future use of SSR course cards by the client, since we only want to use them when rendering with SSR
		}
		this.state = {
			courseCards: initialCourseCards || [],
			placeholders: [],
			hasMore: true,
			loading: !initialCourseCards,
			signedIn: signedIn,
			title: title,
			subtitle: subtitle,
			url: url,
			requestId: 0,
			filterIdentifier,
			forceAPagination: true,
		}
		if (initialCourseCards) {
			this.state.hasMore = initialCourseCards.length >= this.getQuantity() - infiltrators.length // If SSR, make sure the hasMore gets a correct value
			this.state.forceAPagination = false // If SSR, make sure the paginator doesn't paginate on mount
		}
		this.getCollectionCourses = this.getCollectionCourses.bind(this)
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const aFilterChangeOccurred = prevState.filterIdentifier !== nextProps.filterIdentifier
		if (nextProps.useFilter && aFilterChangeOccurred) {
			// Reset the state when a filter change occurs so the paginator will fetch new course cards
			return {
				courseCards: [],
				hasMore: true,
				loading: true,
				filterIdentifier: nextProps.filterIdentifier,
				forceAPagination: true,
			}
		} else {
			return null
		}
	}

	currentListType() {
		if (this.props.type === 'specific_host_pages' || this.props.type === 'host_pages') return 'HOST_PAGE'
		if (isServerRendering()) {
			return this.props.ssrCourseCardData?.filterState?.listType || 'COURSE' // Default to COURSE for SSR course cards without filter
		} else {
			const urlParams = new URLSearchParams(useLocation().search)
			const listType = urlParams.get('type')
			if (listType === 'date') {
				return 'COURSE_DATE'
			} else if (listType === 'price_high') {
				return 'PRICE_HIGH'
			} else if (listType === 'price_low') {
				return 'PRICE_LOW'
			} else {
				return 'COURSE'
			}
		}
	}

	getCollectionCourses() {
		let { courseCards, requestId } = this.state
		let { id, type, useFilter, dispatch, isPreview } = this.props
		const quantity = this.getQuantity()
		const placeholders = []
		for (let i = 0; i < quantity; i++) {
			placeholders.push({})
		}
		const newRequestId = ++requestId
		this.setState({ loading: true, placeholders: placeholders, requestId: newRequestId, forceAPagination: false })
		const url = new URL(location.href)
		let locationIds = url.searchParams.getAll('l[]')
		let globalTagIds = url.searchParams.getAll('gt[]')
		let skillTagIds = url.searchParams.getAll('st[]')
		/*		let timesIds = url.searchParams.getAll('t[]')
		let collectionIds = url.searchParams.getAll('c[]')
		*/
		const searchQuery = url.searchParams.get('q')
		const listType = this.currentListType()
		const offset = courseCards.length
		const params = {
			quantity,
			offset,
			id,
			use_filter: useFilter,
			type,
			listType,
			locations: locationIds,
			global_tags: globalTagIds,
			skill_tags: skillTagIds,
			// times: timesIds,
			// collections: collectionIds,
			search_query: searchQuery,
			locale: i18n.language,
		}
		// The cacheKey is made up of params, since these will always be unique for each type of course card HTTP request
		const cacheKey = JSON.stringify(params)
		const cache = window.filterCache
		if (cache[cacheKey]) {
			// Cache exists, so set the state from cache instead of fetching the state again
			if (useFilter) {
				dispatch({
					type: 'SET_STATE_FROM_CACHE',
					data: { cachedState: cache[cacheKey].filterProviderState },
				})
			}
			this.setState(cache[cacheKey].courseCardWrapperState, () => {
				if (searchQuery && offset === 0) {
					// Scroll down to the search results after a search is made and the results have loaded
					pageScroller('FILTER_CONTAINER')
				}
			})
		} else {
			// Cache doesn't exist, so fetch the course cards and filter state from scratch
			axios({
				method: 'get',
				url: '/api/v1/public/course_cards',
				params,
			}).then(response => {
				// Make sure only the last made request gets processed, to prevent a slower request from presenting old information to the user
				if (newRequestId < this.state.requestId) return
				const newCourseCards = response.data.courseCards
				const title = response.data.title ? response.data.title : this.state.title
				const subtitle = response.data.subtitle ? response.data.subtitle : this.state.subtitle
				const url = response.data.url ? response.data.url : this.state.url
				const newFilterState = response.data.courseCount
				const state = {
					courseCards: [...courseCards, ...newCourseCards],
					loading: false,
					title,
					subtitle,
					url,
					placeholders: [],
					hasMore: newCourseCards.length === quantity,
				}
				// Cache the course card state
				cache[cacheKey] = {
					courseCardWrapperState: state,
				}
				if (useFilter) {
					// Update and cache the filter state
					dispatch({
						type: 'FILTER_COURSE_COUNT',
						data: { newFilterState, cacheKey },
					})
					if (searchQuery && offset === 0) {
						// Scroll down to the search results after a search is made and the results have loaded
						pageScroller('FILTER_CONTAINER')
					}
				}
				this.setState(state)
			})
		}
	}
	getQuantity() {
		const { courseCards } = this.state
		const { infiltrators, screenTypes } = this.props
		const numberOfInfiltrators = infiltrators.length
		let quantity
		if (this.props.quantity) {
			quantity = this.props.quantity
		} else if (this.currentListType() === 'COURSE_DATE') {
			quantity = screenTypes.isPhone || screenTypes.isTablet ? 12 : 24
		} else {
			// IF CHANGING THE NUMBER OF CARDS PER FETCH, ALSO CHANGE THEM IN get_list_type_quantity SO THE SSR FETCH IS CORRECT
			// Number of cards to fetch depends on screen size, since we display a different numbers of cards per screen
			// Do modulo on number of cards (including infiltrators) per row in case screen size has changed, so that every row is filled
			if (screenTypes.isPhone || screenTypes.isTablet) {
				quantity = 6 + 2 - ((courseCards.length + numberOfInfiltrators) % 2) // 2 per row
			} else {
				quantity = 12 + 4 - ((courseCards.length + numberOfInfiltrators) % 4) // 4 per row
			}
		}
		return quantity
	}
	renderCourseCardWrapper() {
		let {
			hideTitle,
			hideSubtitle,
			hideShowAll,
			rows,
			paginate,
			paginateWithButton,
			optimizeRows,
			infiltrators,
			cardsPerRow,
			type,
			sideBarContent,
			t,
		} = this.props
		const listType = this.currentListType()
		if (listType === 'COURSE_DATE') infiltrators = [] // Don't use infiltrators for course date list type
		const { courseCards, loading, hasMore, placeholders, title, subtitle, url, forceAPagination } = this.state
		let rowLength = this.props.rowLength || 'three-thirds'
		// Set rules for top margin for course cards when cardsPerRow is set
		let cardsPerRowMarginString = ''
		if (cardsPerRow) {
			for (let i = 1; i <= cardsPerRow; i++) {
				cardsPerRowMarginString += `${i > 1 ? ', ' : ''}&:nth-child(${i})`
			}
			cardsPerRowMarginString += '{margin-top: 0;}'
		}
		const courseCardsWrapperId = 'COURSE_CARDS_WRAPPER_' + listType
		const linkButtonText = this.props.linkButtonText || t('common:buttons.browseAll')
		return (
			<>
				<div>
					{hideTitle || !title || <Title>{title}</Title>}
					{hideSubtitle || !subtitle || <Subtitle>{subtitle}</Subtitle>}
				</div>
				<Paginator
					courseCardsWrapperId={courseCardsWrapperId}
					paginate={this.getCollectionCourses}
					hasMore={paginate && hasMore}
					loading={loading}
					paginateWithButton={paginateWithButton}
					forceAPagination={forceAPagination}
					t={t}
				>
					{courseCards.length === 0 && !loading ? (
						type !== 'experts' && <CenteredSection>{t('shared:filterContainer.noMatch')}</CenteredSection>
					) : (
						<Items
							id={courseCardsWrapperId}
							rows={rows}
							rowLength={rowLength}
							optimizeRows={optimizeRows}
							cardsPerRow={cardsPerRow}
							cardsPerRowMarginString={cardsPerRowMarginString}
							listType={listType}
						>
							{courseCards.map((item, index) => {
								let card
								if (listType === 'HOST_PAGE') {
									card = <HostCard host={item} />
								} else if (listType === 'COURSE_DATE') {
									card = <CourseDateCard course={item} courseDate={item.courseDate} includeTitle />
								} else {
									card = <CourseCard course={item} />
								}
								return (
									<React.Fragment key={index}>
										{infiltrators[0]?.index === index && infiltrators[0].element}
										{infiltrators[1]?.index === index && infiltrators[1].element}
										{card}
									</React.Fragment>
								)
							})}
							{placeholders.map((item, index) => (
								<React.Fragment key={index}>
									{courseCards.length === 0 && infiltrators[0]?.index === index && infiltrators[0].element}
									{courseCards.length === 0 && infiltrators[1]?.index === index && infiltrators[1].element}
									{listType === 'COURSE_DATE' ? <CourseDatePlaceholder /> : <Placeholder />}
								</React.Fragment>
							))}
							{/* Add the infiltrator last if there aren't enough course cards to make it appear at its target index */}
							{infiltrators[0]?.index >= courseCards.length && placeholders.length === 0 && infiltrators[0].element}
							{infiltrators[1]?.index >= courseCards.length && placeholders.length === 0 && infiltrators[1].element}
						</Items>
					)}
				</Paginator>
				{/*{!hideShowAll && url && hasMore && !paginateWithButton && !paginate && (
					<CenteredSection>
						<>
							{sideBarContent ? (
								<SideBarLinkButton sideBarContent={sideBarContent} url={url}>
									{linkButtonText}
								</SideBarLinkButton>
							) : (
								<Link to={url}>
									<Button color="OUTLINED_BLACK">{linkButtonText}</Button>
								</Link>
							)}
						</>
					</CenteredSection>
				)}*/}
			</>
		)
	}

	render() {
		const { skipMargins, lazyLoad, offset, divider, useFilter } = this.props
		const { loading, courseCards } = this.state
		return skipMargins ? (
			<LazyLoad height={700} offset={offset}>
				{this.renderCourseCardWrapper()}
			</LazyLoad>
		) : (
			(courseCards.length === 0 && !loading && !useFilter) || (
				<>
					{divider && <Divider />}
					<DynamicPageSection
						margins={{ top: theme.margins.smallInt }}
						lazyLoad={lazyLoad}
						height={700}
						offset={offset}
					>
						{this.renderCourseCardWrapper()}
					</DynamicPageSection>
				</>
			)
		)
	}
}

const SideBarLinkButton = ({ children, sideBarContent, url }) => {
	const { dispatch } = useContext(ContentSideBarContext)
	// Disabled because we don't allow host page to be opened in side bar anymore, unless it is a preview
	/*const onSideBarClick = event => {
		// The card is an a tag link so it can be indexed by Google, but we want the overlay to open instead of
		// the user being rerouted to the host/course page, so prevent the default of the a tag.
		event.preventDefault()
		dispatch({ type: 'NEW_CONTENT', data: { content: sideBarContent, contentType: 'HOST' } })
	}*/
	return (
		<a href={url} onClick={onSideBarClick}>
			<Button color="OUTLINED_BLACK">{children}</Button>
		</a>
	)
}

CourseCardsWrapper.propTypes = {
	hideTitle: PropTypes.bool, // Set to true to hide the title
	useFilter: PropTypes.bool, // Set to true if the course cards should be filterable, e.g. on /c. It will then fetch filter data from the server. Defaults to false.
	filterIdentifier: PropTypes.string, // An identifier for this filter state, i.e. its URL since each URL is unique for each filter state
	linkButtonText: PropTypes.string, // Optional text for link button
	type: PropTypes.string, // Set what type of course cards to fetch
	ssrCourseCardData: PropTypes.object, // Initial course cards for SSR
	hideSubtitle: PropTypes.bool, // Set to true to hide the subtitle
	hideShowAll: PropTypes.bool, // Set to true to hide the "Show all" button, even if a URL is present
	skipMargins: PropTypes.bool, // Set to true to skip top and bottom margins
	lazyLoad: PropTypes.bool, // Should it be lazy loaded? Unnecessary if it is shown right away on the page
	offset: PropTypes.number, // Lazy load offset. Defaults to 100px
	paginate: PropTypes.bool, // If set to true, the component will paginate when scrolled to bottom
	quantity: PropTypes.number, // Set the number of cards to fetch from the server. If absent, then it is set depending on screen size
	rows: PropTypes.number, // Limit the number of course cards shown by setting a strict maximum number of rows. Useful when fetching e.g. 8 cards for desktop but only showing 2 rows on mobile when screen size changes
	optimizeRows: PropTypes.bool, // Instead of setting a fixed number of allowed rows, this can be used to automatically optimize the rows depending on screen size. E.g. 2 rows when 2 cards per row and 1 row when 6 cards per row. Can not be used together with rows option
	divider: PropTypes.bool, // Add a divider on top of the course cards wrapper
	sideBarContent: PropTypes.object, // Make the See more button open up the side bar instead
	rowLength: PropTypes.string, // If a row length is less than the whole page width, fewer cards will be displayed per row
	cardsPerRow: PropTypes.number, // Choose a specific number of cards per row. Can not be used together with row-length since that option chooses the number of cards per row automatically depending on screen size.
	infiltrators: PropTypes.array, // Inject one or more elements amongst the course cards. Specify what element and on what index to inject it, e.g. {element: <div />, index: 0}
}

CourseCardsWrapper.defaultProps = {
	infiltrators: [],
}

// If there is a FilterProvider in the tree above, these course cards will subscribe to it to allow filtering
const FilterConsumerWrapper = props => {
	const { t } = useTranslation()
	return (
		<ScreenSizeContext.Consumer>
			{({ screenTypes }) => (
				<FilterContext.Consumer>
					{({ filterState, dispatch }) => {
						return (
							<CourseCardsWrapper
								dispatch={dispatch}
								filterIdentifier={useLocation().href}
								t={t}
								screenTypes={screenTypes}
								{...props}
							/>
						)
					}}
				</FilterContext.Consumer>
			)}
		</ScreenSizeContext.Consumer>
	)
}

export default FilterConsumerWrapper
