import React, { useContext } from 'react'
import UserContext from '../../../contexts/user/UserContext'
import ConditionalLink from '../other/ConditionalLink'
import { getMarketplace } from '../../../utils/applicationHelper'

const EjblaLogoMarketplace = ({ fillColor = '#111111', onClick, disableClick, openedInPwa = false }) => {
	const { userState } = useContext(UserContext)
	const rootPath = userState.pwa.rootPath
	return (
		<ConditionalLink
			to={rootPath}
			onClick={onClick}
			style={{ display: 'flex', pointerEvents: disableClick ? 'none' : 'auto' }}
		>
			<div style={{ fontFamily: 'Edo', color: fillColor, textAlign: 'center' }}>
				<div style={{ fontSize: '30px' }}>{getMarketplace().slug}</div>
				<div>by Ejbla</div>
			</div>
		</ConditionalLink>
	)
}

export default EjblaLogoMarketplace
