import loadable from '@loadable/component'

export const StartPage = loadable(() => import('../start_page/StartPage'))
export const HostsPage = loadable(() => import('../host_pages/HostsPage'))
export const CoursesPage = loadable(() => import('../courses/CoursesPage'))
export const SubCategoryPage = loadable(() => import('../sub_categories/show/SubCategoryPage'))
export const CategoryPage = loadable(() => import('../categories/show/CategoryPage'))
export const SubCategoriesPage = loadable(() => import('../sub_categories/SubCategoriesPage'))
export const CollectionPage = loadable(() => import('../collections/CollectionPage'))
export const CityPage = loadable(() => import('../cities/CityPage'))
export const InternalServerErrorPage = loadable(() => import('../errors/InternalServerErrorPage'))
export const NotFoundPage = loadable(() => import('../errors/NotFoundPage'))
export const ResetPasswordPage = loadable(() => import('../users/ResetPasswordPage'))
export const UserOnboardingPage = loadable(() => import('../users/UserOnboardingPage'))
export const CourseDatePage = loadable(() => import('../course_dates/CourseDatePage'))
export const LiveSessionsPage = loadable(() => import('../static_pages/LiveSessionsPage'))
export const NewExpertFormPage = loadable(() => import('../static_pages/NewExpertFormPage'))
export const EjblaProPage = loadable(() => import('../static_pages/ejbla_pro_page/EjblaProPage'))
export const ExpertInfo = loadable(() => import('../static_pages/ExpertInfo'))
export const BookingPage = loadable(() => import('../purchases/show/BookingPage'))
export const PurchaseLimboPage = loadable(() => import('../static_pages/PurchaseLimboPage'))
export const GiftCardBusinessPage = loadable(() => import('../static_pages/GiftCardBusinessPage'))
export const ThankYouPage = loadable(() => import('../purchases/thank_you/ThankYouPage'))
export const CheckoutPage = loadable(() => import('../purchases/new/CheckoutPage'))
export const GiftCardPage = loadable(() => import('../static_pages/gift_card_page/GiftCardPage'))
export const ContactPage = loadable(() => import('../static_pages/ContactPage'))
export const BrandsPage = loadable(() => import('../static_pages/BrandsPage'))
export const PricingPage = loadable(() => import('../static_pages/PricingPage'))
export const TeamPage = loadable(() => import('../static_pages/TeamPage'))
export const HostPage = loadable(() => import('../host_pages/show/HostPage'))
export const CoursePage = loadable(() => import('../courses/show/CoursePage'))
export const MarketplacePage = loadable(() => import('../marketplaces/show/MarketplacePage'))
export const BookingConditionsPage = loadable(() => import('../courses/booking_conditions_page/BookingConditionsPage'))
export const AdminApp = loadable(() => import('../admin/layout/AdminApp'))
export const AdminGatePage = loadable(() => import('../static_pages/AdminGatePage'))
