import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next-new'
import { enableBodyScroll } from '../../utils/disableBodyScroll'
import useLocation from '../../hooks/useLocation'

import { PageWrapper, ThemeWrapper } from '../reusable_components/layout/PageComponents'
import { Route, Switch, useLocation as reactRouterUseLocation } from 'react-router-dom'
import LoadContainer from '../admin/layout/LoadContainer'
import ContentSideBar from '../reusable_components/modals/content_side_bar/ContentSideBar'
import CourseSideBarProvider from '../../contexts/content_side_bar/ContentSideBarProvider'
import SessionModal from '../reusable_components/modals/session/SessionModal'
import Header from './header/Header'
import UserConsumer from '../reusable_components/middleware/UserConsumer'
import HelmetHead from './HelmetHead'
// import MemorizedIdWrapper from '../reusable_components/middleware/MemorizedIdWrapper'
import GroupInquiryModal from '../reusable_components/modals/group_inquiry/GroupInquiryModal'

// OBS: ROUTES ADDED HERE NEEDS TO BE ADDED BOTH TO AppRoutes.imports-hmr AND AppRoutes.imports-loadable
import {
	AdminApp,
	AdminGatePage,
	BookingConditionsPage,
	BookingPage,
	CategoryPage,
	CheckoutPage,
	CollectionPage,
	CourseDatePage,
	CoursePage,
	HostPage,
	InternalServerErrorPage,
	MarketplacePage,
	NotFoundPage,
	PricingPage,
	PurchaseLimboPage,
	ResetPasswordPage,
	StartPage,
	SubCategoryPage,
	ThankYouPage,
} from './AppStructurePages.imports-hmr'
import { isMarketplace, isSessionStorageAvailable, scrollToTop } from '../../utils/applicationHelper'
import MemorizedIdWrapper from '../reusable_components/middleware/MemorizedIdWrapper'
import UserContext from '../../contexts/user/UserContext'
import useRoutes from '../../hooks/useRoutes'

// OBS: REMEMBER TO CHANGE ANY ROUTES IN robots.txt IF CHANGED HERE AND ALSO INCLUDED THERE
// requiresProp: Set to true if it requires a prop from the controller to load, e.g. course page needs to load the course.
// requiredProp: The required prop that needs loading if requiresProp is set to true. If this is absent, the page will reload to fetch it. This happens if a user navigates back with react router from another loaded page
// hideFooter: Hides the page footer
// exact: The path needs to match exact, e.g. start page needs to be exactly /sv so it doesn't render on other paths that the 404 page needs to pick up
// helmetData: Data to insert to the page's <head>
let appRoutes = ({ t, ...rest }) => {
	const props = {
		t,
		...rest,
		fallback: (
			<PageWrapper hideFooter>
				<LoadContainer fetching />
			</PageWrapper>
		),
	}
	return [
		{
			path: '/co/:id',
			component: (
				<MemorizedIdWrapper {...props}>
					<CollectionPage />
				</MemorizedIdWrapper>
			),
			skipSidePadding: true,
		},
		...(isMarketplace()
			? [
					{
						path: '/ca/:categoryId/:subCategoryId',
						component: (
							<MemorizedIdWrapper {...props}>
								<SubCategoryPage />
							</MemorizedIdWrapper>
						),
						helmetData: {
							defaultLocalizedUrls: false,
						},
						skipSidePadding: true,
						fillCurrentPageToTop: true,
					},
					{
						path: '/:location/ca/:categoryId/:subCategoryId',
						component: (
							<MemorizedIdWrapper hasLocation {...props}>
								<SubCategoryPage />
							</MemorizedIdWrapper>
						),
						helmetData: {
							defaultLocalizedUrls: false,
						},
						skipSidePadding: true,
						fillCurrentPageToTop: true,
					},
					{
						path: '/ca/:categoryId',
						component: (
							<MemorizedIdWrapper {...props}>
								<CategoryPage />
							</MemorizedIdWrapper>
						),
						helmetData: {
							defaultLocalizedUrls: false,
						},
						skipSidePadding: true,
						fillCurrentPageToTop: true,
					},
					{
						path: '/:location/ca/:categoryId',
						component: (
							<MemorizedIdWrapper hasLocation {...props}>
								<CategoryPage />
							</MemorizedIdWrapper>
						),
						helmetData: {
							defaultLocalizedUrls: false,
						},
						skipSidePadding: true,
						fillCurrentPageToTop: true,
					},
					/*{
			path: '/ca',
			component: <SubCategoriesPage {...props} />,
			helmetData: {
				title: t('helmet:subCategoriesPage.title'),
				description: t('helmet:subCategoriesPage.description'),
			},
		},*/
					/*{
			path: '/cities/:id',
			component: (
				<MemorizedIdWrapper {...props}>
					<CityPage />
				</MemorizedIdWrapper>
			),
			skipSidePadding: true,
		},*/
				]
			: [
					{
						path: '/h/:id',
						component: <HostPage {...props} />,
						helmetData: {
							skipHelmetData: true, // Only use this if a <HelmetHead/> is present in the component. Otherwise the language switcher won't work since it relies on the rel="alternate" link in HelmetHead
						},
						skipSidePadding: true,
						fillCurrentPageToTop: true,
					},
					{
						path: '/pricing',
						component: <PricingPage {...props} />,
						skipSidePadding: true,
						staticPage: true,
						helmetData: {
							title: t('helmet:startPage.title'),
							description: t('helmet:startPage.description'),
							image: 'static_assets/giftcard/skillcard',
						},
					},
				]),
		{
			path: '/:location/c/:id/purchase_policy',
			component: <BookingConditionsPage {...props} />,
		},
		{
			path: '/:location/c/:id',
			component: <CoursePage {...props} />,
			skipSidePadding: true,
			fillCurrentPageToTop: true,
			helmetData: {
				skipHelmetData: true, // Only use this if a <HelmetHead/> is present in the component. Otherwise the language switcher won't work since it relies on the rel="alternate" link in HelmetHead
			},
		},
		{
			path: '/d/:id',
			component: <CourseDatePage {...props} />,
			hideFooter: true,
			requiresProp: true,
			requiredProp: props.courseDate,
			skipSidePadding: true,
			skipTopMargin: true,
		},
		/*{
			path: '/h',
			component: <HostsPage {...props} />,
		},
		{
			path: '/:location/c',
			component: <CoursesPage {...props} />,
			helmetData: {
				title: t('helmet:coursesPage.title'),
				description: t('helmet:coursesPage.description'),
				image: 'static_assets/giftcard/skillcard',
			},
			skipSidePadding: true,
		},
		{
			path: '/c',
			component: <CoursesPage {...props} />,
			helmetData: {
				title: t('helmet:coursesPage.title'),
				description: t('helmet:coursesPage.description'),
				image: 'static_assets/giftcard/skillcard',
			},
			skipSidePadding: true,
		},*/
		{
			path: '/b/new',
			component: <CheckoutPage {...props} />,
			hideFooter: true,
			requiresProp: true,
			requiredProp: props.purchaseType === 'b',
			skipSidePadding: true,
			skipTopMargin: true,
		},
		{
			path: '/b/:id/thank_you',
			component: <ThankYouPage {...props} />,
			hideFooter: true,
			requiresProp: true,
			requiredProp: props.courseDate,
			skipSidePadding: true,
			skipTopMargin: true,
		},
		{
			path: '/b/:id',
			component: <BookingPage {...props} />,
			hideFooter: true,
			requiresProp: true,
			requiredProp: props.tickets,
			skipSidePadding: true,
			skipTopMargin: true,
		},
		{
			path: '/purchase_limbo_b',
			component: <PurchaseLimboPage type="purchasedBooking" {...props} />,
			hideFooter: true,
			requiresProp: true,
			requiredProp: props.stripeKey,
		},
		/*{
			path: '/purchase_limbo_g',
			component: <PurchaseLimboPage type="purchasedGiftCard" {...props} />,
			hideFooter: true,
			requiresProp: true,
			requiredProp: props.stripeKey,
		},
		{
			path: '/gc/new',
			component: <CheckoutPage {...props} />,
			hideFooter: true,
			requiresProp: true,
			requiredProp: props.purchaseType === 'gc',
		},
		{
			path: '/gc/:id',
			component: <ThankYouPage {...props} />,
			hideFooter: true,
			requiresProp: true,
			requiredProp: props.giftcardValue,
		},*/
		/*{
			path: '/gift_card',
			component: <GiftCardPage {...props} />,
			helmetData: {
				title: t('helmet:giftcard.title'),
				description: t('helmet:giftcard.description'),
				image: 'static_assets/giftcard/skillcard',
			},
		},*/
		{
			path: '/reset_password',
			component: <ResetPasswordPage {...props} />,
			skipSidePadding: true,
			skipTopMargin: true,
		},
		{
			path: '/admin_gate',
			component: <AdminGatePage {...props} />,
			skipSidePadding: true,
			skipTopMargin: true,
			fillCurrentPageToTop: true,
			staticPage: true,
			hideFooter: true,
		},
		/*{
			path: '/u/:id/onboarding',
			component: <UserOnboardingPage {...props} />,
			requiresProp: true,
			requiredProp: props.subCategories,
		},*/
		{
			path: '/500',
			component: <InternalServerErrorPage {...props} />,
		},
		isMarketplace()
			? {
					path: '',
					component: <MarketplacePage {...props} />,
					exact: true,
					skipSidePadding: true,
					fillCurrentPageToTop: true,
				}
			: {
					path: '',
					component: <StartPage {...props} />,
					exact: true,
					skipSidePadding: true,
					staticPage: true,
					fillCurrentPageToTop: true,
					helmetData: {
						title: t('helmet:startPage.title'),
						description: t('helmet:startPage.description'),
						image: 'static_assets/giftcard/skillcard',
					},
				},
	]
}

const AppStructure = ({ locale, categoryLinks, collectionLinks, footerLinks, whiteLabelIcon, ...props }) => {
	const { t, i18n } = useTranslation()
	const location = useLocation()
	// OBS: DON'T USE useContext HERE OR HAVE ANY CONTEXT CONSUMERS IN PARENTS, IT WILL RERENDER ALL CHILDREN WHEN THEIR CONTEXT UPDATES. PASS ANY DATA YOU NEED HERE FROM PROPS, OR LOOK AT InitializePwa FOR AN EXAMPLE IF YOU NEED ACCESS TO A CONTEXT HERE. ONLY PROVIDERS ARE ALLOWED IN PARENTS. CONSUMERS ARE ONLY ALLOWED IN CHILDREN, E.G WRAPPING CHILDREN WITH UserConsumer HERE IS OKAY
	const childProps = { ...props, t, i18n, categoryLinks, whiteLabelIcon }
	const headerProps = { categoryLinks, collectionLinks, t, whiteLabelIcon }

	return (
		<ThemeWrapper>
			<ResetPage />
			<InitializePwa />
			<SessionModal />
			<GroupInquiryModal />
			<CourseSideBarProvider whiteLabelIcon={whiteLabelIcon}>
				<ContentSideBar t={t} />
				<Switch>
					{!isMarketplace() && (
						<Route path={`/${locale}/admin/host`}>
							<>
								<Header {...headerProps} toolsHeader />
								<AdminApp adminPage="HOST" {...childProps} toolsHeader />
							</>
						</Route>
					)}
					<Route path={`/${locale}/admin/account`}>
						<>
							<Header {...headerProps} />
							<AdminApp adminPage="ACCOUNT" {...childProps} />
						</>
					</Route>
					{appRoutes(childProps).map(
						(
							{
								path,
								component,
								hideFooter,
								skipSidePadding,
								skipTopMargin,
								requiresProp,
								requiredProp,
								exact,
								helmetData,
								staticPage,
								fillCurrentPageToTop,
								fixedBackground,
							},
							index,
						) => {
							return (
								<Route
									path={`/${locale + path}`}
									key={index}
									exact={exact}
									component={() => (
										<>
											<Header {...headerProps} staticPage={staticPage} fillCurrentPageToTop={fillCurrentPageToTop} />
											{(requiresProp && requiredProp) || !requiresProp ? (
												<PageWrapper
													hideFooter={hideFooter || !!whiteLabelIcon}
													footerLinks={footerLinks}
													skipSidePadding={skipSidePadding}
													skipTopMargin={skipTopMargin}
													key={location.pathname}
													fillCurrentPageToTop={fillCurrentPageToTop}
												>
													{helmetData?.skipHelmetData || <HelmetHead {...helmetData} topLevelHelmet />}
													<UserConsumer>{component}</UserConsumer>
												</PageWrapper>
											) : (
												<ReloadPage />
											)}
										</>
									)} // Use current pathname as key to force a re render if a user navigates from e.g. a course page to another course page, since only the ID in the URL changes and not the Route component
								/>
							)
						},
					)}
					<Route path="/">
						<Header {...headerProps} />
						<PageWrapper footerLinks={footerLinks}>
							<NotFoundPage {...childProps} />
						</PageWrapper>
					</Route>
				</Switch>
			</CourseSideBarProvider>
		</ThemeWrapper>
	)
}

// Reset page after a user navigates to a new Route
// 1. Scroll to the top of the page
// 2. Enable back body scroll (if it for some reason was disabled and not re-enabled)
const ResetPage = () => {
	const { pathname } = reactRouterUseLocation()
	useEffect(() => {
		// Only scroll to top when changing page, not on initial page load.
		if (window.notInitialLoad) {
			scrollToTop()
			enableBodyScroll()
		} else {
			window.notInitialLoad = true
		}
	}, [pathname]) // Only run if pathname is new, e.g. a new Route was rendered
	return null
}

// Used when data is missing from the server, so reload the page to get that data
const ReloadPage = () => {
	window.location.reload()
	return (
		<PageWrapper hideFooter>
			<LoadContainer fetching />
		</PageWrapper>
	)
}

// PWA-related activities on mount
const InitializePwa = () => {
	const { userState, dispatch } = useContext(UserContext)
	useEffect(() => {
		const reportPwaLaunched = () => {
			if (userState.pwa.openedInPwa) return
			if (isSessionStorageAvailable()) sessionStorage.setItem('pwaInstalled', 'TRUE') // It is better to set this too many times than too few times, in case it gets cleared. Using session storage so it is local to the PWA, so it doesn't transfer over if they open up ejbla in the browser
			dispatch({
				// Any data added here also needs to be added to pwaSsr in UserProvider
				type: 'PWA',
				data: {
					...userState.pwa,
					openedInPwa: true,
					rootPath: userState.user.signedIn
						? useRoutes().admin.host.overview.path
						: useRoutes().admin.host.overview.url, // If they are not signed in we want them to fully load that page so they get redirected
				},
			})
		}
		if (document.referrer.startsWith('android-app://')) {
			// Keeping this in case we add the PWA to the play store as a TWA, in which case it will go in here
			reportPwaLaunched()
			return
		} else if (
			navigator.standalone ||
			window.matchMedia('(display-mode: standalone)').matches ||
			(isSessionStorageAvailable() && sessionStorage.getItem('pwaInstalled')) // If the PWA is opened in fullscreen, then it isn't in standalone, so look for this in localStorage as backup. Although most likely
		) {
			// Launched as a PWA (because we use the standalone display mode in the webmanifest), so don't listen to PWA install events since it is already installed
			reportPwaLaunched()
			return
		}
		window.matchMedia('(display-mode: standalone)').addEventListener('change', evt => {
			if (evt.matches) {
				// The user was in the browser but chose to open the page in the PWA instead. Happens e.g when they install it, then the tab closes automatically and is opened in the PWA instead
				reportPwaLaunched()
			}
		})
		window.addEventListener('beforeinstallprompt', e => {
			// Prevent the mini-infobar from appearing on mobile
			e.preventDefault()
			// Stash the event so it can be triggered later.
			dispatch({ type: 'PWA', data: { ...userState.pwa, readyEvent: e } })
		})
		window.addEventListener('appinstalled', () => {
			// The app was installed, so remove the event
			dispatch({ type: 'PWA', data: { ...userState.pwa, readyEvent: null } })
		})
	}, [])
	return null
}

export default AppStructure
