import React, { useState } from 'react'
import { theme } from '../../../utils/theme'
import FollowButton from '../../reusable_components/buttons/FollowButton'
import styled from 'styled-components'
import { ScarcityBadge, StyledScarcityBadge } from '../../reusable_components/other/CourseComponents'
import IntersectionObserverWrapper from '../../reusable_components/responsive/IntersectionObserverWrapper'
import { PageSidePaddingWrapper } from '../../reusable_components/layout/PageComponents'
import { ContentSliderImage } from './ContentSlider'
import { limitRows } from '../../reusable_components/PageMixins'

export const HeaderWrapper = styled.div`
	overflow: hidden;
	position: relative;
	margin-bottom: ${theme.margins.small};
	${StyledScarcityBadge} {
		z-index: 2;
		top: unset;
		right: unset;
	}
	> button {
		position: absolute;
		bottom: ${theme.margins.XSmall};
		right: ${theme.margins.XSmall};
		z-index: 2;
	}
	img {
		border-radius: 0;
	}
	> div:last-child {
		background: #0000003d;
		position: absolute;
		color: white;
		width: 100%;
		height: 100%;
		top: 0;
		.slick-slider.slick-initialized {
			display: grid;
		}
		> div {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;
			h1 {
				${theme.breakpoints.tablet.upToAndIncluding} {
					${limitRows(6)};
					line-height: 50px; // A higher line-height than font-size is needed when limiting rows so some letters (e.g. "g") doesn't get cut out on the last row
				}
				${theme.breakpoints.tablet.overAndExcluding} {
					${limitRows(3)};
					font-size: 50px;
					line-height: 60px;
				}
			}
		}
	}
`
export const TagBadge = styled.div`
	background: ${theme.colors.lightGray};
	border-radius: 20px;
	padding: ${theme.paddings.XSmall};
	box-shadow: 0 0 2px 0 #0000001f;
`
/*const TagsWrapper = styled.div`
	position: absolute;
	z-index: 2;
	top: ${theme.margins.XSmall};
	left: ${theme.margins.XSmall};
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	> a:not(:first-child) {
		margin-top: ${theme.margins.XSmall};
	}
`*/

const HeaderSection = ({
	id,
	image,
	title,
	tags,
	closeSideBar,
	physical,
	locationPath,
	scarcity,
	isOpenedInContentSideBar,
}) => {
	const [showScarcity, setShowScarcity] = useState(false)
	if (!image) return null
	return (
		<HeaderWrapper>
			<ContentSliderImage image={image} alt={title} isOpenedInContentSideBar={isOpenedInContentSideBar} />
			{/*	<TagsWrapper>
				{tags.map((tag, key) => (
					<Link to={tag.path} onClick={closeSideBar} key={key}>
						<TagBadge>
							<P4>#{tag.slug}</P4>
						</TagBadge>
					</Link>
				))}
				{physical || (
					// Only show tag with link to filter for online courses since the location displayed is too specific, like "Flen", which we don't have a filtering option for (we only have Stockholm and online)
					<Link to={locationPath} onClick={closeSideBar}>
						<TagBadge>
							<P4>#online</P4>
						</TagBadge>
					</Link>
				)}
			</TagsWrapper>*/}
			{scarcity && (
				<IntersectionObserverWrapper defaultMountedToDom={true} callback={() => setShowScarcity(true)}>
					<ScarcityBadge scarcity={scarcity} show={showScarcity} />
				</IntersectionObserverWrapper>
			)}
			<FollowButton color="WHITE" favouritableType="course" favouritableId={id} showHeart gtmId="GTM_SAVE_WORKSHOP" />
			<div>
				<PageSidePaddingWrapper>
					<h1>{title}</h1>
				</PageSidePaddingWrapper>
			</div>
		</HeaderWrapper>
	)
}

export default HeaderSection
