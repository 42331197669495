import React from 'react'
import styled from 'styled-components'
import { useQuery } from 'urql'
import gql from 'graphql-tag'
import Rating from '../../../../reusable_components/reviews/Rating'
import { theme } from '../../../../../utils/theme'

import { B2, B3, CallToActionPageSection, Markdown } from '../../../../reusable_components/Typography'
import ResponsiveImage from '../../../../reusable_components/responsive/ResponsiveImage'
import LoadContainer from '../../../layout/LoadContainer'
import {
	Divider,
	PageSection,
	SmallPaddingWrapper,
	SmallPageSectionDivider,
	SmallPageSectionRepulsiveRow,
} from '../../../../reusable_components/layout/PageComponents'
import CrocodileRight from '../../../../reusable_components/svg_icons/CrocodileRight'
import useRoutes from '../../../../../hooks/useRoutes'
import LinkButton from '../../../../reusable_components/buttons/LinkButton'
import { Link } from 'react-router-dom'
import Cancel from '../../../../reusable_components/svg_icons/Cancel'
import { AdminBreadcrumbs } from '../../../layout/PageComponents'
import { DateAndTimeInfo } from '../../../../reusable_components/other/CourseComponents'
import QrCodesModal from './QrCodesModal'

export const TicketCourseWrapper = styled.div`
	display: flex;
	${theme.breakpoints.phone.overAndExcluding} {
		> div {
			width: 60%;
			:first-child {
				width: 40%;
				margin-right: ${props => props.theme.margins.small};
			}
			:last-child {
				display: flex;
				flex-direction: column;
			}
		}
	}
	${theme.breakpoints.phone.upToAndIncluding} {
		flex-direction: column;
	}
`
export const TicketImageWrapper = styled.div`
	margin-bottom: ${theme.margins.small};
	${theme.breakpoints.phone.overAndExcluding} {
		margin-bottom: 0;
		display: flex;
		justify-content: center;
		max-width: 250px;
	}
`
const TransactionsWrapper = styled(SmallPaddingWrapper)`
	max-width: 300px;
	background: ${theme.colors.lightGray};
	margin-top: ${theme.margins.small};
	td {
		:first-child {
			padding-right: ${theme.paddings.small};
		}
		:last-child {
			text-align: end;
		}
	}
`

const ticketsFragment = gql`
	fragment ticketDetails on Ticket {
		id
		userId
		canSubmitReview
		newReviewUrl
		receiptUrl
		editPath
		transferPath
		cancelOrReschedulePath
		qrCode
		fullName
		user {
			id
			fullName
		}
		courseDate {
			id
			date
			nextDate
			timeFrame
			nextTimeFrame
			hasEnded
			status
			url
			payOnSite
			qrCodes
			consecutiveCourseDates {
				date
				timeFrame
				hasEnded
			}
		}
		review {
			rating
		}
		booking {
			userId
			createdAt
			totalPrice
			amountPaidByGiftcard
			bookingVatAmount
			bookingVatPercentage
			seats
			refundedSum
			refundedAt
			editUrl
			payOnSiteAmount
			tickets {
				qrCode
				fullName
				id
			}
			user {
				fullName
			}
			course {
				title
				status
				defaultImagePath
				url
				purchasePolicyUrl
				host {
					fullName
					hostPage {
						path
					}
				}
			}
		}
	}
`
const getTickets = gql`
	query ($id: ID!) {
		user(id: $id) {
			userId
			email
			upcomingTickets {
				...ticketDetails
			}
			pastTickets {
				...ticketDetails
			}
			cancelledTickets {
				...ticketDetails
			}
			pausedTickets {
				...ticketDetails
			}
			refundedTickets {
				...ticketDetails
			}
		}
	}
	${ticketsFragment}
`

const TicketsPage = props => {
	const userId = props.user.id
	const t = props.t
	const routes = useRoutes()

	const [result] = useQuery({
		query: getTickets,
		variables: { id: userId },
	})

	const { data, fetching, error } = result

	if (fetching || error) return <LoadContainer error={error} fetching={fetching} />

	const user = data.user
	const upcomingTickets = user.upcomingTickets
	const pastTickets = user.pastTickets
	const cancelledTickets = user.cancelledTickets
	const pausedTickets = user.pausedTickets
	const refundedTickets = user.refundedTickets
	const numberOfTickets = upcomingTickets.length + pastTickets.length + cancelledTickets.length
	return (
		<>
			<AdminBreadcrumbs>{t('admin:ticketsPage.pageTitle')}</AdminBreadcrumbs>
			<p>{t('admin:ticketsPage.pageSubTitle')}</p>
			{numberOfTickets === 0 && (
				<div>
					<br />
					<p>{t('admin:ticketsPage.nothingBooked')}</p>
					{/*<br />
					<p>
						<Link to={routes.marketplace.index.url}>{t('admin:ticketsPage.browseEjblas')}</Link>
					</p>*/}
				</div>
			)}
			<TicketsSection user={user} tickets={pausedTickets} type="paused" t={t} />
			<TicketsSection user={user} tickets={upcomingTickets} type="upcoming" t={t} />
			<TicketsSection user={user} tickets={pastTickets} type="past" t={t} />
			<TicketsSection user={user} tickets={cancelledTickets} type="cancelled" t={t} />
			<TicketsSection user={user} tickets={refundedTickets} type="refunded" t={t} />
		</>
	)
}

const TicketsSection = ({ user, tickets, t, type }) => {
	const editParticipantsButton = (
		<SmallPageSectionRepulsiveRow>
			{t('admin:ticketsPage.updateTickets')}
			<CrocodileRight />
		</SmallPageSectionRepulsiveRow>
	)
	return (
		<PageSection>
			{tickets.length > 0 && (
				<>
					<h2>{t(`admin:ticketsPage.${type}`)}</h2>
					<div>
						{tickets.map((ticket, index) => {
							const booking = ticket.booking
							let courseDate = ticket.courseDate
							const course = booking.course
							const host = course.host
							const hostPage = host.hostPage
							const userIsParticipating = user.userId === ticket.userId // The user is participating. Can be false if the user is the booker, but is not participating him/herself
							const isPublished = courseDate?.status === 'published'
							const isBooker = booking.userId === user.userId
							return (
								<React.Fragment key={index}>
									{index !== 0 && <SmallPageSectionDivider />}
									<PageSection>
										<TicketCourseWrapper>
											<TicketImageWrapper>
												<ResponsiveImage type="SQUARE" imagePath={course.defaultImagePath} />
											</TicketImageWrapper>
											<div>
												<div>
													{type === 'past' &&
														(ticket.review ? (
															<div>
																<Rating rating={ticket.review.rating} />
															</div>
														) : (
															ticket.canSubmitReview && (
																<div>
																	<a href={ticket.newReviewUrl}>
																		<Rating rating={0} />
																		&nbsp;<LinkButton>{t('admin:ticketsPage.leaveReview')}</LinkButton>
																	</a>
																</div>
															)
														))}
													<B2 useAsTitle>{course.title}</B2>
													{courseDate && <DateAndTimeInfo courseDate={courseDate} />}
													<p>
														{t('admin:ticketsPage.hostedBy')}{' '}
														{hostPage?.path ? <Link to={hostPage.path}>{host.fullName}</Link> : host.fullName}
													</p>
													<br />
													{!userIsParticipating && (
														<>
															<p>{t('admin:ticketsPage.notParticipating', { email: user.email })}</p>
															<br />
														</>
													)}
													{courseDate && (!isPublished || (userIsParticipating && !booking.refundedAt)) && (
														<>
															<Divider />
															<a href={courseDate.url}>
																<SmallPageSectionRepulsiveRow>
																	{t('common:buttons.viewBooking')}
																	<CrocodileRight />
																</SmallPageSectionRepulsiveRow>
															</a>
														</>
													)}
												</div>
												{!booking.refundedAt && ['upcoming', 'paused'].includes(type) && (
													<>
														{courseDate.qrCodes && userIsParticipating && (
															<>
																<Divider />
																<QrCodesModal isBooker={isBooker} ticket={ticket} tickets={booking.tickets} />
															</>
														)}
														{type === 'upcoming' && (
															<>
																<Divider />
																{isBooker ? (
																	<a href={booking.editUrl} target="_blank">
																		{editParticipantsButton}
																	</a>
																) : (
																	<Link to={ticket.editPath}>{editParticipantsButton}</Link>
																)}
															</>
														)}
														{(isBooker || type === 'upcoming') && (
															<>
																<Divider />
																<Link to={ticket.cancelOrReschedulePath}>
																	<SmallPageSectionRepulsiveRow>
																		{type === 'upcoming'
																			? t('admin:ticketsPage.cancelBooking')
																			: t('admin:cancelOrReschedulePage.reschedule')}
																		<CrocodileRight />
																	</SmallPageSectionRepulsiveRow>
																</Link>
															</>
														)}
													</>
												)}
												{isBooker && courseDate?.payOnSite && ['upcoming', 'paused'].includes(type) && isPublished && (
													<TransactionsWrapper>
														<B3>{t('admin:courseDatesPage.payOnSite')}</B3>
														<br />
														<p>{t('common:payments.payOnSite')}</p>
														<br />
														<p>
															<b>{t('common:payments.totalsum')}:</b>&nbsp;{booking.payOnSiteAmount}:-
														</p>
													</TransactionsWrapper>
												)}
												{isBooker && courseDate?.payOnSite && !isPublished && (
													<TransactionsWrapper>
														<B3>{t('copy:courseDatePage.cancelled')}</B3>
														<br />
														<Markdown linkTarget="_blank">
															{t('copy:courseDatePage.cancelledTextPayOnSite', { url: course.purchasePolicyUrl })}
														</Markdown>
														<br />
														<p>
															<b>{t('common:payments.totalsum')}:</b>&nbsp;{booking.payOnSiteAmount}:-
														</p>
													</TransactionsWrapper>
												)}
												{isBooker ? (
													<TransactionsWrapper>
														<B3>{t('common:payments.payment')}</B3>
														<br />
														{!userIsParticipating && (
															<p>
																{booking.seats > 1 || !ticket.user?.fullName
																	? t('common:payments.bookedForOthers')
																	: t('common:payments.bookedFor', { name: ticket.user.fullName })}
															</p>
														)}
														<p>{booking.createdAt}</p>
														<br />
														<table>
															<tbody>
																<tr>
																	<td>{t('common:payments.totalsum')}:</td>
																	<td>{booking.amountPaidByGiftcard + booking.totalPrice}:-</td>
																</tr>
																{booking.amountPaidByGiftcard > 0 && (
																	<>
																		<tr>
																			<td>{t('common:payments.paidbygiftcard')}:</td>
																			<td>{booking.amountPaidByGiftcard}:-</td>
																		</tr>
																	</>
																)}
																<tr>
																	<td>
																		<i>
																			{t('common:payments.inclvat2')}
																			{booking.bookingVatPercentage > 0 ? ` ${booking.bookingVatPercentage}%` : ''}:
																		</i>
																	</td>
																	<td>
																		<i>{booking.bookingVatAmount}:-</i>
																	</td>
																</tr>
															</tbody>
														</table>
														{booking.refundedAt && (
															<>
																<br />
																<B3>{t('common:payments.refund')}</B3>
																<p>{booking.refundedAt}</p>
																<table>
																	<tbody>
																		<tr>
																			<td>{t('common:payments.totalsum')}:</td>
																			<td>-{booking.refundedSum}:-</td>
																		</tr>
																	</tbody>
																</table>
															</>
														)}
														<a href={ticket.receiptUrl} target="_blank">
															<CallToActionPageSection>
																{t('common:buttons.downloadreceipt')}
																<CrocodileRight />
															</CallToActionPageSection>
														</a>
													</TransactionsWrapper>
												) : (
													<TransactionsWrapper>
														<B3>{t('common:payments.payment')}</B3>
														<br />
														<p>{t('common:payments.bookedBy', { name: booking.user.fullName })}</p>
														{booking.refundedAt && (
															<>
																<br />
																<div style={{ display: 'flex', alignItems: 'center' }}>
																	<Cancel />
																	&nbsp;<p>{t('common:payments.refundStatus', { date: booking.refundedAt })}</p>
																</div>
																<br />
																<b>{t('common:payments.refundTo', { name: booking.user.fullName })}</b>
															</>
														)}
													</TransactionsWrapper>
												)}
											</div>
										</TicketCourseWrapper>
									</PageSection>
								</React.Fragment>
							)
						})}
					</div>
				</>
			)}
		</PageSection>
	)
}

export default TicketsPage
